import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import {
  whyHero,
  whyFullVideo,
  whyLargeData,
  whyQuote,
  whyBigBlock,
  whyProducts,
  whyBigReview,
} from "../../data/why-data";
import SEO from "../../components/seo";
import ogImage from "../../images/global_assets/og-image.png";

const NewWhyHero = loadable(() =>
  import("../../components/_NewAnimated/NewWhyHero/NewWhyHero")
);

const VideoFull = loadable(() =>
  import("../../components/_NewAnimated/NewVideo/VideoFull")
);
const NewLargeFeatures = loadable(() =>
  import("../../components/_NewAnimated/NewLargeFeatures/NewLargeFeatures")
);
const FullQuote = loadable(() =>
  import("../../components/_NewAnimated/FullQuote/FullQuote")
);
const BigBlockImgs = loadable(() =>
  import("../../components/_NewAnimated/BigImgBlock/BigBlockImgs")
);
const ProductViewer = loadable(() =>
  import("../../components/_NewAnimated/ProductViewer/ProductViewer")
);
const BigReviews = loadable(() =>
  import("../../components/_NewAnimated/BigReviews/BigReviews")
);
const CapterraBanner = loadable(() =>
  import("../../components-v2/CapterraBanner")
);

const WhySpoton = () => {
  return (
    <Layout normalBanner relMenuHeight={72} className="non-fixed-width">
      <SEO
        title="Why SpotOn | Business Software | Point-of-Sale Systems | Payments"
        description="There are many choices when it comes to new technology for your restaurant or small business, so why choose SpotOn? Because we only succeed when you succeed. We’ll equip you with cutting-edge software, point-of-sale, and payment solutions, and we’ll also make sure you know how to get the most out of them, with local setup and service and 24/7 support included."
        image={`https://spoton.com/${ogImage}`}
      />
      <NewWhyHero sectionData={whyHero} />
      <VideoFull sectionData={whyFullVideo} bgDark />
      <NewLargeFeatures sectionData={whyLargeData} bgDark />
      <ProductViewer sectionData={whyProducts} />
      <BigBlockImgs sectionData={whyBigBlock} bgDark />
      <FullQuote sectionData={whyQuote} bgDark />
      {/* <BigReviews sectionData={whyBigReview} bgDark={false} /> */}
      <CapterraBanner
        className="pt-16 lg:pt-20 pb-20 lg:pb-40"
        imageName="capterra-pos.png"
        title={`Rated the <span class="text-primary">best</span> POS system based on real user reviews<span class="text-primary">.</span>`}
        withCta
        ctaLabel="See the results"
        ctaTarget="https://datainsights.capterra.com/p/point-of-sale/226928/spoton/references?c=&c=&c=&sc=6320065"
      />
    </Layout>
  );
};

export default WhySpoton;
